<template>
  <b-modal
    ref="common-details-permission-request"
    modal-class="modal-primary common-details-permission"
    scrollable
    hide-footer
    centered
    size="md"
    @hidden="$emit('hide')"
  >
    <template #modal-title>
      <span class="title-bold">DETAIL: {{ type }} - {{ clientName }}</span>
    </template>

    <b-overlay variant="transparent" :show="false">
      <b-container fluid>
        <b-row class="mt-1">
          <b-col :cols="12">
            <b-input-group v-for="(item, index) in jsonData" :key="index">
              <template #prepend>
                <b-input-group-text class="prepend__width_number"
                  >{{ index + 1 }}.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text">{{
                item.label
              }}</b-input-group-text>
              <b-form-input :value="item.value" class="prepend__width_value" />
            </b-input-group>
            <!-- <b-input-group>
              <template #prepend>
                <b-input-group-text class="prepend__width_number"
                  >{{ jsonData.length + 1 }}.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text">{{
                "Commentary"
              }}</b-input-group-text>
              <b-form-input :value="commentary" class="prepend__width_value" />
            </b-input-group> -->
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>
<script>
import modalMixin from "@/mixins/modal";
export default {
  props: {
    jsonData: {
      type: Array,
      required: true,
    },
    clientName: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    commentary: {
      type: String,
      required: false,
    },
  },
  mixins: [modalMixin],
  data() {
    return {
      cols: 6,
    };
  },
  mounted() {
    this.toggleModal("common-details-permission-request");
  },
  watch: {
    screenWidth() {
      if (this.screenWidth >= 1300) {
        this.cols = 6;
      } else {
        this.cols = 12;
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap");

.title-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}
.prepend__width_number {
  justify-content: center;
  width: 3rem;
  border-radius: 0px 0px 0px 0px;
}
.prepend__width_text {
  justify-content: left;
  width: 16rem;
  border-radius: 0px 0px 0px 0px;
  border-right: none !important;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #99989a !important;
}
.prepend__width_value {
  color: #99989a !important;
  border-radius: 0px 0px 0px 0px !important;
  pointer-events: none;
  justify-content: right;
  text-align: right;
}
</style>

<style lang="scss">
.common-details-permission {
  .modal-dialog {
    min-width: 37vw !important;
  }
}
</style>