<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @onChangeCurrentPage="$refs['permissionTable'].refresh()"
      @reload="$refs['permissionTable'].refresh()"
    >
      <b-table
        id="permissionTable"
        slot="table"
        ref="permissionTable"
        :items="myProvider"
        :fields="fields"
        :filter="filter"
        table-class="text-nowrap"
        responsive="sm"
        no-border-collapse
        sticky-header="70vh"
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(lead_name)="data">
          <div
            class="d-flex flex-column justify-content-center align-items-start"
          >
            <router-link
              target="_blank"
              v-if="data.item.client_account_id"
              :to="{
                name: 'debt-solution-dashboard',
                params: { idClient: data.item.client_account_id },
              }"
            >
              {{ data.item.lead_name }}
            </router-link>
            <router-link
              v-else
              target="_blank"
              :to="{
                name: 'debtsolution-lead-show',
                params: { id: data.item.lead_id },
              }"
            >
              {{ data.item.lead_name }}
            </router-link>
            <span>{{ data.item.account }}</span>
            <span>{{ data.item.mobile }}</span>
          </div>
        </template>
        <template #cell(code)="data">
          <div>
            {{ data.item.code | limitChars(19) }}
          </div>
        </template>
        <template #cell(created_at)="data">
          <div class="">
            {{ data.item.created_at | myGlobalDay }}
          </div>
        </template>

        <template #cell(type_name)="data">
          <div>
            <span
              class="permission-badge"
              :style="{
                backgroundColor: data.item.color_bg,
                color: data.item.color_text,
              }"
            >
              {{ data.item.type_name }}
            </span>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="">
            <b-badge
              :variant="
                data.item.status === 'APPROVED'
                  ? 'light-success'
                  : 'light-warning'
              "
            >
              {{ data.item.status }}
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <feather-icon
              :id="'ap-' + data.index"
              @click="
                changeStatusRequest(
                  data.item.action_id,
                  data.item.code,
                  2,
                  data.item.request_type
                )
              "
              icon="CheckIcon"
              class="cursor-pointer text-success mx-1"
            ></feather-icon>
            <feather-icon
              :id="'re-' + data.index"
              @click="
                changeStatusRequest(
                  data.item.action_id,
                  data.item.code,
                  3,
                  data.item.request_type
                )
              "
              icon="XIcon"
              class="cursor-pointer text-danger"
            ></feather-icon>
            <b-tooltip
              variant="success"
              :target="'ap-' + data.index"
              noninteractive
              >Approve Request</b-tooltip
            >
            <b-tooltip
              variant="danger"
              :target="'re-' + data.index"
              noninteractive
              >Reject Request</b-tooltip
            >
          </div>
        </template>
        <template #cell(reason)="data">
          <change-sms :SmsData="data.item.reason" />
        </template>
        <template #cell(detail)="data">
          <div class="d-flex justify-content-center">
            <feather-icon
              v-if="data.item.request_type === 1"
              icon="SearchIcon"
              class="cursor-pointer text-primary"
              v-b-tooltip.hover
              title="Show detail"
              @click="openModalDetail(data.item.code, data.item.request_type)"
            ></feather-icon>
            <feather-icon
              v-else
              @click="openModalCommonDetail(data.item.json_data, data.item.lead_name, data.item.type_name, data.item.reason)"
              icon="SearchIcon"
              class="cursor-pointer text-primary"
              v-b-tooltip.hover
              title="Show detail"
            ></feather-icon>
          </div>
        </template>
      </b-table>
    </filter-slot>

    <view-detail-permission-action
      v-if="modalShow"
      :code="modalData.code"
      :type="modalData.type"
      @close="modalShow = false"
    />
    <common-details
      v-if="modalShowDetail"
      :json-data="dataJson"
      :client-name="selectedClientName"
      :commentary="selectedCommentary"
      :type="selectedType"
      @hide="modalShowDetail = false"
    />
  </div>
</template>

<script>
import Fields from "@/views/debt-solution/views/permission-action/data/Fields.data.js";
import Filters from "@/views/debt-solution/views/permission-action/data/Filters.data.js";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
import PermissionService from "@/views/commons/components/permissions/services/permission.service.js";
import { mapGetters, mapActions } from "vuex";
import ViewDetailPermissionAction from "@/views/debt-solution/views/permission-action/components/VIewDetailPermissionAction.vue";
import CommonDetails from "@/views/commons/components/permissions/components/CommonDetails.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";
export default {
  components: {
    ViewDetailPermissionAction,
    CommonDetails,
    ChangeSms,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    tabActive() {
      return this.$route.meta.tab;
    },

    fields() {
      let auxFields = [...Fields];

      if (this.tabActive === 2) {
        auxFields[6].visible = true;
        auxFields[7].visible = true;
        auxFields[8].visible = false;
      } else {
        auxFields[6].visible = false;
        auxFields[7].visible = false;
        auxFields[8].visible = true;
      }

      return auxFields.filter((item) => item.visible);
    },
  },
  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Code",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filter: [],
      startPage: 0,
      toPage: 0,
      items: [],
      sortBy: "1",
      sortDesc: true,
      modalShow: false,
      modalShowDetail: false,
      modalData: {
        code: null,
        type: null,
      },
      dataJson: [],
      selectedType: null,
      selectedClientName: null,
      selectedCommentary: null,
    };
  },

  methods: {
    ...mapActions({
      updateRequestAction: "PermissionAction/A_COUNTER_REQUEST_PENDING",
    }),

    async myProvider(ctx) {
      try {
        this.addPreloader();
        const params = {
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          date_from: this.filter[0] ? this.filter[0].model : null,
          date_to: this.filter[1] ? this.filter[1].model : null,
          type_request: this.filter[2] ? this.filter[2].model : null,
          status: this.filter[3] ? this.filter[3].model : null,
          user_au: this.currentUser.user_id,
          module_id: this.moduleId,
          tab_active: this.tabActive,
        };
        const { data } = await PermissionService.getRequestsPermissionAction(
          params
        );
        this.startPage = data.from ? data.from : 0;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        return data.data || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },

    async getTypesRequest() {
      try {
        const params = {
          module_id: this.moduleId,
        };
        const { data } = await PermissionService.getTypesRequestAction(params);
        this.filter[2].options = data;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    async changeStatusRequest(id, code, status, type) {
      const result = await this.showQuestionSwal(
        "Do you want to perform this action?"
      );
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          const params = {
            id: id,
            code: code,
            status: status,
            type_request: type,
            user_aproved: this.currentUser.user_id,
          };

          const response = await PermissionService.getChangeStatusRequest(
            params
          );
          await this.updateRequestAction({
            module_id: this.currentUser.modul_id,
            user_id: this.currentUser.user_id,
          });

          const { data } = await PermissionService.getCounter({
            module_id: this.currentUser.modul_id,
            user_id: this.currentUser.user_id,
          });

          const payload = {
            routeName: "permission-section-debtsolution",
            tag: data,
          };

          this.$store.commit(
            "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
            payload
          );

          this.$refs["permissionTable"].refresh();
        } catch (error) {
          this.showErrorSwal(e);
        } finally {
          this.removePreloader();
        }
      }
    },
    openModalDetail(code, type) {
      this.modalData.code = code;
      this.modalData.type = type;
      this.modalShow = true;
    },
    openModalCommonDetail(jsonData, clientName, typeName, reason) {
      this.dataJson = jsonData;
      this.selectedClientName = clientName;
      this.selectedType = typeName;
      this.selectedCommentary = reason;
      this.modalShowDetail = true;
    },
    filterVisible() {
      let auxFilter = [...Filters];

      if (this.tabActive === 1) {
        auxFilter[3].visible = false;
      } else {
        auxFilter[3].visible = true;
      }
      this.filter = auxFilter.filter((item) => item.visible);
    },
  },

  created() {
    this.getTypesRequest();
  },

  mounted() {
    this.filterVisible();
  },
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();

    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
};
</script>

<style scoped>
.permission-badge {
  padding: 2px 4px;
  border-radius: 10px;
  font-weight: bold;
}
</style>
