export default [
  {
    key: "lead_name",
    label: "Client",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "code",
    label: "CODE",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "type_name",
    label: "TYPE",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "reason",
    label: "Reason",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "request_by",
    label: "Requested By",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "created_at",
    label: "Created At",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },

  {
    key: "attended_by",
    label: "Attended By",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "detail",
    label: "Detail",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
];
