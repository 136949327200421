<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('onlyCloseModal')"
    :title="'UPDATE: ' + objCall.direction"
    no-close-on-backdrop
  >
    <div class="d-block">
      <validation-observer ref="form">
        <b-container>
          <b-row>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="module_id"
                rules="required"
              >
                <b-form-group label="Type:">
                  <v-select
                    v-model="call.type"
                    label="name"
                    :options="types"
                    :reduce="(value) => value.id"
                    :class="errors[0] ? 'border-danger' : ''"
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" v-if="call.type === 1 || call.type === 3">
              <validation-provider
                v-slot="{ errors }"
                name="from_module"
                rules="required"
              >
                <b-form-group label="From Module:">
                  <v-select
                    v-model="call.from_module"
                    label="name"
                    :options="modules"
                    :reduce="(value) => value.id"
                    :class="errors[0] ? 'border-danger' : ''"
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="call.type === 2 || call.type === 3">
              <validation-provider
                v-slot="{ errors }"
                name="lead_id"
                rules="required"
              >
                <b-form-group label="Lead:">
                  <vue-autosuggest
                    ref="autocomplete"
                    v-model="lead_name"
                    :suggestions="filteredOptions"
                    :get-suggestion-value="getSuggestionValue"
                    :class="{'border-danger': !validLead}"
                    :input-props="
                      errors[0]
                        ? {
                            id: 'email-subject',
                            class: 'form-control border-danger w-100',
                            placeholder: '',
                          }
                        : {
                            id: 'email-subject',
                            class: 'form-control w-100',
                            placeholder: 'SEARCH LEAD',
                          }
                    "
                    @input="onInputChange"
                    @selected="selectHandler"
                  >
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item">{{
                        suggestion.item.value
                      }}</span>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="call.type === 1 || call.type === 2">
              <validation-provider
                v-slot="{ errors }"
                name="to_module"
                rules="required"
              >
                <b-form-group label="To Module:">
                  <v-select
                    v-model="call.to_module"
                    label="name"
                    :options="modules"
                    :reduce="(value) => value.id"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-container>
      </validation-observer>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="$emit('onlyCloseModal')"
          >Cancel</b-button
        >
        <b-button variant="primary" @click="saveCall"> Save </b-button>
      </div>
    </template>
  </b-modal>
</template>
  
  <script>
import { mapGetters } from "vuex";
import RCService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: { VueAutosuggest },

  props: {
    objCall: {
      type: Object,
      required: false,
      default: function () {
        return {
          call_id: null,
          direction: null,
          from_module: null,
          to_module: null,
          lead_id: null,
          type: null,
        };
      },
    },
    modules: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  data() {
    return {
      modalShow: true,
      call: this.objCall,
      filteredOptions: [],
      lead_name: null,
      title: null,
      validLead: true,
      types: [
        {
          id: 1,
          name: "INTERNAL",
        },
        { id: 2, name: "INBOUND" },
        { id: 3, name: "OUTBOUND" },
      ],
    };
  },

  methods: {
    async saveCall() {
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      if (
        this.call.lead_id === null &&
        (this.call.type === 2 || this.call.type === 3)
      ){
        this.validLead = false;
        return;
      }
      try {
        this.addPreloader();
        const data = await RCService.asigModuleLeadCallRecording(this.call);
        this.showGenericSuccessSwal({});
        this.$emit("update");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    selectHandler(value) {
      this.call.lead_id = value.item.id;
      this.lead_name = value.item.value;
      this.validLead = true;
    },
    async onInputChange(text) {
      this.lead_id = null;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        return;
      }
      const params = {
        lead_name: text,
      };

      const response = await RCService.searchLeadWithLimit(params);
      this.filteredOptions = [{ data: [...response.data] }];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },

    validateSelectsStatus() {
      switch (this.call.direction) {
        case "UNASSIGNED INBOUND":
          this.call.type = 2;
          break;

        case "UNASSIGNED OUTBOUND":
          this.call.type = 3;
          break;
        case "UNASSIGNED INTERNAL":
          this.call.type = 1;
          break;
        default:
          this.call.type = null;
          break;
      }
    },
  },

  created() {
    this.validateSelectsStatus();
  },
};
</script>
  
  <style>
</style>