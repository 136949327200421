var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":'UPDATE: ' + _vm.objCall.direction,"no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('onlyCloseModal')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('onlyCloseModal')}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveCall}},[_vm._v(" Save ")])],1)]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{staticClass:"d-block"},[_c('validation-observer',{ref:"form"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"module_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type:"}},[_c('v-select',{class:errors[0] ? 'border-danger' : '',attrs:{"label":"name","options":_vm.types,"reduce":function (value) { return value.id; },"clearable":false},model:{value:(_vm.call.type),callback:function ($$v) {_vm.$set(_vm.call, "type", $$v)},expression:"call.type"}})],1)]}}])})],1),(_vm.call.type === 1 || _vm.call.type === 3)?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"from_module","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"From Module:"}},[_c('v-select',{class:errors[0] ? 'border-danger' : '',attrs:{"label":"name","options":_vm.modules,"reduce":function (value) { return value.id; },"clearable":false},model:{value:(_vm.call.from_module),callback:function ($$v) {_vm.$set(_vm.call, "from_module", $$v)},expression:"call.from_module"}})],1)]}}],null,false,396597884)})],1):_vm._e(),(_vm.call.type === 2 || _vm.call.type === 3)?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"lead_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Lead:"}},[_c('vue-autosuggest',{ref:"autocomplete",class:{'border-danger': !_vm.validLead},attrs:{"suggestions":_vm.filteredOptions,"get-suggestion-value":_vm.getSuggestionValue,"input-props":errors[0]
                      ? {
                          id: 'email-subject',
                          class: 'form-control border-danger w-100',
                          placeholder: '',
                        }
                      : {
                          id: 'email-subject',
                          class: 'form-control w-100',
                          placeholder: 'SEARCH LEAD',
                        }},on:{"input":_vm.onInputChange,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.value))])]}}],null,true),model:{value:(_vm.lead_name),callback:function ($$v) {_vm.lead_name=$$v},expression:"lead_name"}})],1)]}}],null,false,3279320674)})],1):_vm._e(),(_vm.call.type === 1 || _vm.call.type === 2)?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"to_module","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"To Module:"}},[_c('v-select',{class:errors[0] ? 'border-danger' : '',attrs:{"label":"name","options":_vm.modules,"reduce":function (value) { return value.id; }},model:{value:(_vm.call.to_module),callback:function ($$v) {_vm.$set(_vm.call, "to_module", $$v)},expression:"call.to_module"}})],1)]}}],null,false,1328793764)})],1):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }