var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filter,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"onChangeCurrentPage":function($event){return _vm.$refs['permissionTable'].refresh()},"reload":function($event){return _vm.$refs['permissionTable'].refresh()}}},[_c('b-table',{ref:"permissionTable",attrs:{"slot":"table","id":"permissionTable","items":_vm.myProvider,"fields":_vm.fields,"filter":_vm.filter,"table-class":"text-nowrap","responsive":"sm","no-border-collapse":"","sticky-header":"70vh","show-empty":""},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-start"},[(data.item.client_account_id)?_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'debt-solution-dashboard',
              params: { idClient: data.item.client_account_id },
            }}},[_vm._v(" "+_vm._s(data.item.lead_name)+" ")]):_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'debtsolution-lead-show',
              params: { id: data.item.lead_id },
            }}},[_vm._v(" "+_vm._s(data.item.lead_name)+" ")]),_c('span',[_vm._v(_vm._s(data.item.account))]),_c('span',[_vm._v(_vm._s(data.item.mobile))])],1)]}},{key:"cell(code)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("limitChars")(data.item.code,19))+" ")])]}},{key:"cell(created_at)",fn:function(data){return [_c('div',{},[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.created_at))+" ")])]}},{key:"cell(type_name)",fn:function(data){return [_c('div',[_c('span',{staticClass:"permission-badge",style:({
              backgroundColor: data.item.color_bg,
              color: data.item.color_text,
            })},[_vm._v(" "+_vm._s(data.item.type_name)+" ")])])]}},{key:"cell(status)",fn:function(data){return [_c('div',{},[_c('b-badge',{attrs:{"variant":data.item.status === 'APPROVED'
                ? 'light-success'
                : 'light-warning'}},[_vm._v(" "+_vm._s(data.item.status)+" ")])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('feather-icon',{staticClass:"cursor-pointer text-success mx-1",attrs:{"id":'ap-' + data.index,"icon":"CheckIcon"},on:{"click":function($event){return _vm.changeStatusRequest(
                data.item.action_id,
                data.item.code,
                2,
                data.item.request_type
              )}}}),_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"id":'re-' + data.index,"icon":"XIcon"},on:{"click":function($event){return _vm.changeStatusRequest(
                data.item.action_id,
                data.item.code,
                3,
                data.item.request_type
              )}}}),_c('b-tooltip',{attrs:{"variant":"success","target":'ap-' + data.index,"noninteractive":""}},[_vm._v("Approve Request")]),_c('b-tooltip',{attrs:{"variant":"danger","target":'re-' + data.index,"noninteractive":""}},[_vm._v("Reject Request")])],1)]}},{key:"cell(reason)",fn:function(data){return [_c('change-sms',{attrs:{"SmsData":data.item.reason}})]}},{key:"cell(detail)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[(data.item.request_type === 1)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer text-primary",attrs:{"icon":"SearchIcon","title":"Show detail"},on:{"click":function($event){return _vm.openModalDetail(data.item.code, data.item.request_type)}}}):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer text-primary",attrs:{"icon":"SearchIcon","title":"Show detail"},on:{"click":function($event){return _vm.openModalCommonDetail(data.item.json_data, data.item.lead_name, data.item.type_name, data.item.reason)}}})],1)]}}])})],1),(_vm.modalShow)?_c('view-detail-permission-action',{attrs:{"code":_vm.modalData.code,"type":_vm.modalData.type},on:{"close":function($event){_vm.modalShow = false}}}):_vm._e(),(_vm.modalShowDetail)?_c('common-details',{attrs:{"json-data":_vm.dataJson,"client-name":_vm.selectedClientName,"commentary":_vm.selectedCommentary,"type":_vm.selectedType},on:{"hide":function($event){_vm.modalShowDetail = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }