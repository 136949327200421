<template>
  <b-modal
    :title="code + ' | Permissions Request'"
    v-model="modalShow"
    no-close-on-backdrop
    @hide="$emit('close')"
    size="xmd"
    hide-footer
  >
    <div class="d-block">
      <b-table responsive :fields="fields" :items="creditors">
        <template v-slot:cell(unsecured)="data">
          <div class="w-100 text-center">
            <feather-icon v-if="data.item.unsecured == 1" icon="CircleIcon" />
          </div>
        </template>
        <template v-slot:cell(balance)="data">
          $ {{ data.value | currency }}
        </template>
        <template v-slot:cell(monthly)="data">
          $ {{ data.value | currency }}
        </template>
        <template #bottom-row>
          <td colspan="1" />
          <td>
            <div>Total</div>
          </td>
          <td class="bg-info">
            <div class="text-white">$ {{ total_balance | currency }}</div>
          </td>
          <td class="bg-info">
            <div class="text-white">$ {{ total_monthly | currency }}</div>
          </td>
          <td colspan="1" class="bg-info" />
          <td class="bg-info rounded-right">
            <div class="text-white">{{ total_interest + " %" }}</div>
          </td>
          <td colspan="3" />
        </template>
      </b-table>
      <div class="d-flex justify-content-end">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="$emit('close')"
          >Close</b-button
        >
        <!-- <b-button variant="primary" @click="saveExtension"> Save </b-button> -->
      </div>
    </div>
  </b-modal>
</template>
  
  <script>
import PermissionService from "@/views/commons/components/permissions/services/permission.service.js";
import { mapGetters } from "vuex";
import Fields from "@/views/crm/views/sales-made/components/modals/services/debt-solution/fields.data.js";

export default {
  props: {
    code: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    fields(){
      let newFields = [...Fields];
      newFields.pop();
      return newFields;
    }
  },
  data() {
    return {
      modalShow: true,
      creditors: [],
      total_balance: "",
      total_monthly: "",
      total_interest: "",
      permissionType: {
        name: "",
        reason: "",
      },
      request: {
        code: "",
        comment: "",
      },
    };
  },

  methods: {
    async getPermissionRequestType() {
      try {
        this.addPreloader();
        const event_id = this.code.split("-")[2];

        const { data } = await amgApi.post(
          "/sales-made/debt-solution/get-credits-debt-solution",
          {
            event: event_id,
            account: null,
          }
        );

        this.creditors = data;
        if (this.creditors.length > 0) {
          this.total_balance = this.creditors[0].total_balance;
          this.total_monthly = this.creditors[0].total_monthly;
          this.total_interest = this.creditors[0].total_interest;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },

  mounted() {
    this.getPermissionRequestType();
  },
};
</script>
  
  <style>
</style>