<template>
  <div>
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @onChangeCurrentPage="getCallRecording"
        @reset-all-filters="resetAllFilters"
        @reload="getCallRecording"
      >
        <template #buttons>
          <div class="d-flex" v-if="moduleId === 16">
            <div class="mx-2">
              <div>
                Migrate Yesterday:
                <b-badge variant="light-success">{{ counts.ysaved }}</b-badge>
              </div>
              <div>
                Not Migrate Yesterday:
                <b-badge variant="light-danger">{{ counts.ynsaved }}</b-badge>
              </div>
            </div>
            <div>
              <div>
                Total Migrate:
                <b-badge variant="light-success"> {{ counts.tsaved }}</b-badge>
              </div>
              <div>
                Total Not Migrate:
                <b-badge variant="light-danger">{{ counts.tnsaved }}</b-badge>
              </div>
            </div>
          </div>
        </template>
        <b-table
          id="claimsList"
          slot="table"
          ref="claimsList"
          :items="listCallRecording"
          :fields="fields"
          :filter="filter"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
          sticky-header="70vh"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(from)="data">
            <div class="d-flex align-items-center justify-content-start">
              <b-avatar class="mr-1" variant="light-success">
                <feather-icon icon="PhoneOutgoingIcon" size="16"></feather-icon>
              </b-avatar>
              <div>
                <div style="margin-left: 2px">
                  <b-link
                    v-if="statusRouter === 2 && moduleId !== 16"
                    :to="{
                      name: getRouterName(data.item)[0],
                      params: getRouterName(data.item)[1],
                    }"
                    class="mb-1 text-warning"
                  >
                    {{ data.item.from_name }}</b-link
                  >
                  <div v-else>
                    {{ data.item.from_name ? data.item.from_name : "-" }}
                  </div>
                </div>
                <div>
                  <b-badge variant="light-success"
                    >{{ data.item.from_number }}
                  </b-badge>
                </div>
              </div>
            </div>
          </template>

          <template #cell(to)="data">
            <div class="d-flex align-items-center justify-content-start">
              <b-avatar
                class="mr-1"
                :variant="getCallStatus(data.item.status)[0]"
                v-b-tooltip.hover
                :title="data.item.status"
              >
                <feather-icon
                  :icon="getCallStatus(data.item.status)[1]"
                  size="16"
                ></feather-icon>
              </b-avatar>
              <div>
                <b-link
                  v-if="statusRouter === 3 && moduleId !== 16"
                  :to="{
                    name: getRouterName(data.item)[0],
                    params: getRouterName(data.item)[1],
                  }"
                  class="mb-1 text-warning"
                >
                  {{ data.item.to_name }}</b-link
                >
                <div v-else style="margin-left: 2px">
                  {{ data.item.to_name ? data.item.to_name : "-" }}
                </div>
                <div>
                  <b-badge :variant="getCallStatus(data.item.status)[0]"
                    >{{ data.item.to_number ? data.item.to_number : "-" }}
                  </b-badge>
                </div>
              </div>
            </div>
          </template>

          <template #cell(account)="data">
            <div>
              <div
                v-for="(item, index) in JSON.parse(data.item.client_account)"
                :key="index"
              >
                <b-link
                  :to="{
                    name: 'quality-client-dashboard',
                    params: { idClient: item.client_account },
                  }"
                  >{{ item.account }}</b-link
                >
              </div>
            </div>
          </template>

          <template #cell(start_time)="data">
            <div>
              {{ data.item.start_time | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(record_url)="data">
            <div
              class="w-100 d-flex justify-content-around text-center"
              v-if="data.item.was_saved"
            >
              <div
                style="position: sticky; top: 0; z-index: 1"
                v-if="data.item.show_item"
              >
                <wave-surfer-player
                  style="height: 1.5rem; display: block !important"
                  class="audio-recording"
                  :url="data.item.record_url"
                  :options="audioOptions"
                  :wave-surfer-id="`wave-surfer-${data.index}`"
                  :elapsed-time-width="50"
                  :file_name="data.item.original_id"
                  @loading="data.item.isLoading = true"
                  @ready="data.item.isLoading = false"
                  @WaveSurferEvents="load"
                  custom-class="no-overflow"
                  :download="false"
                ></wave-surfer-player>
              </div>
              <feather-icon
                v-else
                @click="data.item.show_item = !data.item.show_item"
                icon="LoaderIcon"
                class="text-primary cursor-pointer"
                v-b-tooltip.hover
                title="Load record"
              ></feather-icon>
            </div>
          </template>
          <template #cell(duration)="data">
            <div class="w-100 d-flex justify-content-around text-center">
              {{ convertMSeconds(data.item.duration) }}
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center align-items-center">
              <feather-icon
                icon="Edit2Icon"
                class="cursor-pointer text-warning"
                @click="openModalAsing(data.item)"
              ></feather-icon>
            </div>
          </template>
        </b-table>
      </filter-slot>
    <modal-asign-module-call
      v-if="showModal"
      :modules="modules"
      :obj-call="call"
      @onlyCloseModal="showModal = false"
      @update="cloaseModalAsing"
    ></modal-asign-module-call>
  </div>
</template>

<script>
import Filters from "@/views/commons/components/ring-central/call-log/data/Filters.data.js";
import Fields from "@/views/commons/components/ring-central/call-log/data/Fields.data.js";
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import { mapGetters } from "vuex";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
import RCService from "@/views/management/views/settings/components/ring-central/services/RCService.js";
import ModalAsignModuleCall from "@/views/commons/components/ring-central/call-log/components/ModalAsignModuleCall.vue";
import RoutesProgram from "@/views/commons/components/ring-central/call-log/data/RoutesName.js";

export default {
  components: { WaveSurferPlayer, ModalAsignModuleCall },

  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Number, Name...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filter: Filters,
      startPage: null,
      toPage: null,
      items: [],
      sortBy: "1",
      sortDesc: true,
      listCallRecording: [],
      modules: [],
      showModal: false,
      call: null,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      counts: {},
      routesPrograms: [...RoutesProgram],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    statusRouter() {
      return this.$route.meta.status; // 1 = Internal 2 = INBOUND 3 = OUTBOUND 4 = OTHERS
    },

    fields() {
      let newFields = [...Fields];
      if (this.moduleId !== 18 || [1, 4].includes(this.statusRouter)) {
        newFields.splice(2, 1);
      }
      if (this.statusRouter !== 4) {
        newFields.splice(newFields.length - 1, 1);
      }
      return newFields;
    },
  },
  methods: {
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.getCallRecording();
    },
    resetSearch() {
      this.searchInput = "";
      this.$refs.claimsList.refresh();
    },
    async getCallRecording() {
      try {
        this.addPreloader();
        const params = {
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          tab: this.statusRouter,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          status: this.filter[2].model,
          module_id:
            this.filter[3] && this.filter[3].model != null
              ? this.filter[3].model
              : this.moduleId,
        };
        const { data } = await CallLogService.getCallLogs(params);
        this.startPage = data.from ? data.from : 0;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        this.listCallRecording = data.data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },

    getCallStatus(status) {
      if (status === "Accepted" || status === "Call connected") {
        return ["light-primary", "PhoneIncomingIcon"];
      }

      return ["light-danger", "PhoneMissedIcon"];
    },

    async getModules() {
      const data = await RCService.getModules();
        this.modules = data;

      if (this.moduleId == 16) {
        this.filter[3].options = data;
      }else{
        this.filter.splice(3, 1);
      }

    },

    openModalAsing(item) {
      this.call = item;
      this.showModal = true;
    },

    async cloaseModalAsing() {
      this.showModal = false;
      await this.getCallRecording();
    },

    async getCountCallRecording() {
      const { data } = await CallLogService.getTotalCountCallRecording({});
      this.counts = data[0];
    },

    getRouterName(item) {
      let route = ["lead-show", { id: item.lead_id }];

      if (this.moduleId === 18) {
        return ["quality-lead-show", { id: item.lead_id }];
      }

      for (const element of this.routesPrograms) {
        if (element.module.includes(this.moduleId)) {
          if (item.client_account.client_account) {
            route = [
              element.dashboardCLient,
              { [element.clientParam]: item.client_account },
            ];
          } else {
            route = [element.dashboardLeads, { id: item.lead_id }];
          }
        }
      }

      return route;
    },
  },

  created() {
    this.getCountCallRecording();
  },

  mounted() {
    this.getModules();
  },
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();

    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
};
</script>